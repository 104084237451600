import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg";
import { CardPrice } from "../../../components/card/CardValues";
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { fase, sendFormSocket } from "../../../components/session/SessionKalyst";
import { BiMoney } from "react-icons/bi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { LuBox, LuLineChart, LuUser2, LuUsers2 } from "react-icons/lu";
import LineChart from "../../../components/charts/LineChart" 
import BarChart from "../../../components/charts/BarChart"
import DonutChart from "../../../components/charts/DonutChart"
import { Box, HandCoins, LucideHandCoins } from 'lucide-react';
import { lineChartDataDashboard, lineChartOptionsDashboard,barChartDataCharts2,barChartOptionsCharts2, donutChartDataCharts1,donutChartOptionsCharts1} from "../../../theme/theme";
import { AlertBox } from "../../../components/heading/AlertBox";
import { FilterKalyst } from "../../../components/filter/FIlterKalyst";

export function PagePainelDashboard(props){  
    // const [dados,setDados] = useState(null);
    // const hoje = new Date(); 
    // const { colorMode } = useColorMode();  
    // const [reload,setReload] = useState(false);
    // const [filtroG,setFiltroG]= useState({id:'hoje',name:'Hoje'});
    // const periods =[
    //     {
    //         name:'Hoje',
    //         i:new Date().toISOString().split('T')[0],
    //         f:new Date().toISOString().split('T')[0]
    //     },
    //     {
    //         name:'Ontem',
    //         i:new Date(new Date().setDate(hoje.getDate()-1)).toISOString().split('T')[0],
    //         f:new Date(new Date().setDate(hoje.getDate()-1)).toISOString().split('T')[0]
    //     },
    //     {
    //         name:'Últimos 7 dias',
    //         i:new Date(new Date().setDate(hoje.getDate()-7)).toISOString().split('T')[0],
    //         f:new Date(new Date().setDate(hoje.getDate()-7)).toISOString().split('T')[0]
    //     },
    //     {
    //         name:'Últimos 15 dias',
    //         i:new Date(new Date().setDate(hoje.getDate()-15)).toISOString().split('T')[0],
    //         f:new Date(new Date().setDate(hoje.getDate()-15)).toISOString().split('T')[0]
    //     },
    //     {
    //         name:'Últimos 30 dias',
    //         i:new Date(new Date().setDate(hoje.getDate()-30)).toISOString().split('T')[0],
    //         f:new Date(new Date().setDate(hoje.getDate()-30)).toISOString().split('T')[0]
    //     },
    //     {
    //         name:'Últimos 90 dias',
    //         i:new Date(new Date().setDate(hoje.getDate()-90)).toISOString().split('T')[0],
    //         f:new Date(new Date().setDate(hoje.getDate()-90)).toISOString().split('T')[0]
    //     }
    // ];  
    // function BuscarDados(reload){
    //     sendFormSocket({campo:'pages', modulo:'report',cmd:'dashboard',form:{reload},callback:(result)=>{
    //         console.log(result);
    //         setDados(result)
    //     }});
    // } 
    // useEffect(()=>{ 
    //     setReload(true);
    //     setTimeout(()=>{
    //         setReload(false);
    //     },50)
    // },[colorMode])
    // useEffect(()=>{
    //     BuscarDados(true);
    //     var interV =setInterval(()=>{
    //         BuscarDados(false);
    //     },500);
    //     return  ()=>{
    //         clearInterval(interV);
    //     }
    // },[]);

    // if(dados == null){ 
    //     return <LoadBg/>
    // }else{   
    //     const boxS={
    //         v0:{
    //             value:((dados.saldos.saldoD+dados.saldos.saldoRAS)*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
    //             info:((dados.saldos.saldoP)*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2})
    //         },
    //         v1:{
    //             value:(dados.saldos.vendasH*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
    //             info:((dados.saldos.vendasH-dados.saldos.vendasO)*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
    //             info2:((dados.saldos.vendasO)*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2})
    //         },
    //         v2:{
    //             value:(dados.saldos.saldoG*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
    //             info:'Total em vendas'
    //         },
    //         v3:{
    //             value:((dados.saldos.numPag>0?dados.saldos.saldoG/dados.saldos.numPag:dados.saldos.saldoG).toString().split('.')[0]*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
    //             info:dados.saldos.numPag
    //         }
    //     } 
    //     //transações
    //     let barVolume = [
    //         {
    //           name: "PIX",
    //           data: [0],
    //         },
    //         {
    //           name: "Cartão",
    //           data: [0],
    //         },
    //         {
    //           name: "Boleto",
    //           data: [0],
    //         }
    //     ];
    //     try {
    //         barVolume[0].data[0]=dados.saldos.numPIX;
    //         barVolume[1].data[0]=dados.saldos.numCRD;
    //         barVolume[2].data[0]=dados.saldos.numBOL;
    //     } catch (error) {
            
    //     }
    //     //rastreios
    //     let barRastreios = [
    //         {
    //           name: "Enviados",
    //           data: [0],
    //         },
    //         {
    //           name: "Pendente",
    //           data: [0],
    //         },
    //         {
    //           name: "Digital",
    //           data: [0],
    //         }
    //     ];
    //     try {
    //         barRastreios[0].data[0]=dados.saldos.rastF;
    //         barRastreios[1].data[0]=dados.saldos.rastP;
    //         barRastreios[2].data[0]=dados.saldos.rastD;
    //     } catch (error) {
            
    //     }
    //     //pedidos pagos
    //     let donutP = [1, 1, 1];
    //     try {
    //         console.log(dados);
    //         if(dados.saldos.saldoPIX>0 || dados.saldos.saldoPIX>0 || dados.saldos.saldoBOL>0){
    //             donutP = [dados.saldos.saldoPIX, dados.saldos.saldoCRD,dados.saldos.saldoBOL];
    //         }  
    //     } catch (error) {
    //         console.log(error.message);
    //     } 
    //     //grafico
    //     let grafico = {"Jan":null, "Fev":null, "Mar":null, "Abr":null, "Mai":null, "Jun":null, "Jul":null, "Ago":null, "Set":null, "Out":null, "Nov":null, "Dez":null};
    //     try {
    //         grafico = dados.graf[filtroG.id]                                    
    //     } catch (error) {
            
    //     } 
    //     return  ( 
    //         <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}} align={'flex-start'} justify='flex-start'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}}   margin = '0 auto'   >
    //             <PageHK>Análise</PageHK>  
    //             {fase==1?
    //                 <AlertBox title='Documentos Pendentes' desc='Valide seus documentos para ativar o acesso completo da sua conta!' url='/document'/>
    //                 :null
    //             }
    //             <Grid
    //                 w={'full'}  
    //                 templateColumns={{base:'repeat(4, 1fr)',sm:'repeat(8, 1fr)',md:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
    //                 gap={'16px'}
    //             >
    //                 <GridItem colSpan={2}  >
    //                     <CardPrice icon={LucideHandCoins} title='Disponível'    value={boxS.v0.value}
    //                     info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxS.v0.info}</Text>}
    //                     infoM={'Saldo a Liberar'}
    //                     />
    //                 </GridItem> 
    //                 <GridItem colSpan={2}  >
    //                     <CardPrice icon={BiMoney} title='Vendas Hoje'  value={boxS.v1.value}
    //                      info={<Text color={dados.saldos.vendasO>dados.saldos.vendasH?'red':'green'} fontWeight='bold'>R$ {boxS.v1.info}</Text>} 
    //                      infoM={<Stack align={'flex-end'}><Text  color={'textLight.100'} _dark={{color:'textDark.100'}}>Em comparação a ontem:</Text><Text  fontWeight={'bold'} color={'orange'}  >{"R$"+boxS.v1.info2}</Text></Stack>}
    //                      />
    //                 </GridItem> 
    //                 <GridItem colSpan={2}  >
    //                     <CardPrice icon={BiMoney} title='Vendas Total'  value={boxS.v2.value} 
    //                     info={<Text color={'textLight.200'} _dark={{color:'textDark.200'}} fontWeight='bold'>Todas as Lojas</Text>} 
    //                     />
    //                 </GridItem>   
    //                 <GridItem colSpan={2}  >
    //                     <CardPrice icon={FaArrowTrendUp} title='Ticket Médio' value={boxS.v3.value} 
    //                     info={<Text color={'green.500'} fontWeight='bold'>{boxS.v3.info} Pago</Text>}
    //                     />
    //                 </GridItem> 
    //             </Grid> 
    //             <Grid
    //                 w={'full'}  
    //                 templateColumns={{base:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
    //                 gap={'16px'}
    //             >
    //                 <GridItem colSpan={{base:8,lg:6}}  >
    //                     <Stack flex='0'  gap={'16px'} >
    //                         <Stack w={'full'}   bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius={'8px'} >
    //                             <Flex p={'16px'} pb='0px'>
    //                                 <SubHK icon={LuLineChart} title={'Volumetria de Transações'}/>
    //                                 <Flex w={'30%'}   >
    //                                 <FilterKalyst title='Filtrar de:' value={''} dados={[
    //                                     {id:'hoje',name:'Hoje'},
    //                                     {id:'ontem',name:'Ontem'},
    //                                     {id:'menos7',name:'Últimos 7 dias'},
    //                                     {id:'menos15',name:'Últimos 15 dias'},
    //                                     {id:'menos30',name:'Últimos 30 dias'},
    //                                     {id:'menos90',name:'Últimos 90 dias'},
    //                                     {id:'menos180',name:'Últimos 180 dias'} ,
    //                                     {id:'anual',name:'Anual'} 
    //                                 ]} onchange={(e)=>{ 
    //                                     if(e!=null && typeof e.id!=='undefined'){
    //                                         setFiltroG(e)
    //                                         setReload({});
    //                                         setTimeout(()=>{setReload(null)})
    //                                     //     const {page:pageS}= getFilter(session) 
    //                                     //     if(e.id.split('_').length==2){
    //                                     //         pageS.t=e.id.split('_')[0];
    //                                     //         pageS.o=e.id.split('_')[1]; 
    //                                     //     }
    //                                     //     setFilterPage(session,pageS); 
    //                                     //     tempLoja = null; 
    //                                     //     setTimeout(()=>{
    //                                     //         BuscarDados(true); 
    //                                     //     },400)
    //                                     } 
    //                                 }} />
    //                             </Flex> 
    //                             </Flex>
    //                             <Stack w='100%' minH='360px'>
    //                                 {reload?null:
    //                                     <LineChart
    //                                         chartData={[
    //                                             {
    //                                               name: "Transações",
    //                                               data:  Object.values(grafico).map((v)=>{return (v*0.01).toFixed(2)}),
    //                                             } 
    //                                           ]}
    //                                         chartOptions={lineChartOptionsDashboard(colorMode,
    //                                              Object.keys(grafico)
    //                                         )}
    //                                     />
    //                                 }
                                    
    //                             </Stack> 
    //                         </Stack>
    //                         <Flex w={'full'} flexDirection={{base:'column',md:'row'}}  gap={'16px'}>
    //                             <Stack w={'100%'}  bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius={'8px'} pb='16px'>
    //                                 <Flex p={'16px'} pb='0px'>
    //                                     <SubHK icon={LuBox} title={'Código de Rastreio'}/>
    //                                 </Flex>
    //                                 <Stack w={'calc(100% - 16px)'} h='250px'> 
    //                                     <BarChart
    //                                         chartData={barRastreios}
    //                                         chartOptions={barChartOptionsCharts2}
    //                                     />
    //                                 </Stack>
    //                             </Stack>
    //                             <Stack w={'100%'}  bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius={'8px'} pb='16px'>
    //                                 <Flex p={'16px'} pb='0px'>
    //                                     <SubHK icon={LuLineChart} title={'Volumetria de Transações'}/>
    //                                 </Flex>
    //                                 <Stack w={'calc(100% - 16px)'} h='250px'> 
    //                                     <BarChart
    //                                         chartData={barVolume}
    //                                         chartOptions={barChartOptionsCharts2}
    //                                     />
    //                                 </Stack>
    //                             </Stack>

                               
    //                         </Flex>
                             
    //                     </Stack>
    //                 </GridItem>
    //                 <GridItem colSpan={{base:8,lg:2}} >
    //                     <Stack minH={'200px'} w={'full'} p={'16px'}  bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius={'8px'}>
    //                             <SubHK icon={HandCoins} title={'Pedidos Pagos'}/> 
    //                             <Stack h={'200px'} w='full'>
    //                                 <DonutChart
    //                                     chartData={donutP}
    //                                     chartOptions={donutChartOptionsCharts1}
    //                                 /> 
    //                             </Stack>
                                 
    //                             <Flex w={'full'}  align='center' justify={'space-between'}>
    //                                 <Text fontSize={{ sm: 'md', lg: 'lg' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold'>
    //                                     PIX
    //                                 </Text>
    //                                 <Text fontSize={{ sm: 'md', lg: 'lg' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold' >
    //                                     R$ { ((dados.saldos.saldoPIX).toString().split('.')[0]*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2})}
    //                                 </Text>
    //                             </Flex> 
    //                             <Progress colorScheme='pix'  h='12px'   bg='lightTema1.400' _dark={{bg:'bgMenuDark.200'}}borderRadius='8px'  value={20} />
    //                             <Flex w={'full'}  align='center' justify={'space-between'}>
    //                                 <Text fontSize={{ sm: 'md', lg: 'lg' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold'>
    //                                     Cartão
    //                                 </Text>
    //                                 <Text fontSize={{ sm: 'md', lg: 'lg' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold' >
    //                                     R$ { ((dados.saldos.saldoCRD).toString().split('.')[0]*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2})}
    //                                 </Text>
    //                             </Flex> 
    //                             <Progress colorScheme='card' h='12px'  bg='lightTema1.400' _dark={{bg:'bgMenuDark.200'}} borderRadius='8px'  value={20} />
    //                             <Flex w={'full'}  align='center' justify={'space-between'}>
    //                                 <Text fontSize={{ sm: 'md', lg: 'lg' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold'>
    //                                     Boleto
    //                                 </Text>
    //                                 <Text fontSize={{ sm: 'md', lg: 'lg' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold' >
    //                                     R$ { ((dados.saldos.saldoBOL).toString().split('.')[0]*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2})}
    //                                 </Text>
    //                             </Flex> 
    //                             <Progress colorScheme='boleto' h='12px'  bg='lightTema1.400' _dark={{bg:'bgMenuDark.200'}}  borderRadius='8px'  value={20} />
                           
    //                     </Stack>
    //                 </GridItem>                 
    //             </Grid> 
    //         </Stack> 
    //     )
    // }
    return null
}