import { Box, Button, ButtonGroup, Flex, Grid, GridItem, Input, InputGroup, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Stack,Text } from "@chakra-ui/react";
import { useRef, useState } from "react";  
import { LuCheckSquare, LuSquare } from "react-icons/lu";  
var temp = null;
export function FilterKalyst({title,value:inputValue=null,dados=[],type='select',onchange=()=>{}}){
    const [mark,setMark] = useState(null); 
    var input = inputValue
    if(dados.length>0){
        dados.forEach((v)=>{
            if(v.id == inputValue){
                input = v;
            }
        })
    } 
    
    const [value,setValue]= useState(inputValue==''?null:input)
    switch (type) {
        case 'input':
            return  (
                <Stack position={'relative'} w='full'>
                    <Text position={'absolute'}  top='5px' left={'10px'} textTransform='uppercase'  color={value==null?'lightTema1.300':'lightTema1.100'} zIndex={'1000'} fontSize='10px' fontWeight={'bold'}  _dark={{color:'textDark.100'}}>{title}</Text>
                    <InputGroup   bg={value==null?'transparent':'lightTema1.300'} _hover={{}} _focus={{outline:'none',boxShadow:'none'}}  position={'relative'}  h='44px'   borderRadius='lg'  pr='0px'     > 
                         <Input opacity={'1'}  _hover={{}} _focus={{outline:'none',boxShadow:'none'}}     lineHeight={'20px'} pt='16px' pl='10px'  h='44px' color={value==null?'lightTema1.300':'lightTema1.100'} borderColor={value==null?'lightTema1.300 !important':'lightTema1.100 !important'}  type={'text'}  resize={'none'}        display={'block'}  _dark={{ color:'lightTema1.100',bg:value==null?'transparent':'lightTema1.300',borderColor:'lightTema1.100 !important'}}  fontFamily={'Inter'}  border={value==null?'2px dashed':'none'} value={value==null?'':value}  fontSize={'14px'} onChange={(e)=>{
                            setValue(e.target.value==''?null:e.target.value);
                            clearTimeout(temp);
                            temp = setTimeout(()=>{
                                onchange(e.target.value==''?null:e.target.value);
                            },500);
                            
                         }} borderRadius='lg'   />
                    </InputGroup>
                </Stack> 
            )
        break;
        case 'select': 
            const ViewDados =   (
                <PopoverBody >
                    {dados.map((v,i)=>{
                        return <Button onClick={()=>{
                            setValue(v);
                            onchange(v);
                        }} key={'btfs'+i} variant={'ghost'} w='100%' px={'10px'} textAlign={'left'} leftIcon={value!==null && v.id==value.id?<LuCheckSquare fontSize={'22px'}  />:<LuSquare fontSize={'22px'}  />} justifyContent='flex-start' gap={'5px'} bg={value!==null && v.id==value.id?'darkTema1.300':'initial'} >{v.name}</Button>
                    })}
                </PopoverBody>
            ); 
            return    (
                <Flex position={'relative'} w='full'  minH={'44px'} align={'center'}  >
                    <Text position={'absolute'} top='5px' left={'10px'}  color={value==null?'lightTema1.300':'lightTema1.100'} textTransform='uppercase'   zIndex={'1000'} fontSize='10px' fontWeight={'bold'}  _dark={{color:'textDark.100'}}>{title}</Text>
                    
                    <Popover placement='bottom-start' closeOnBlur={false}  closeOnEsc={false}  trigger='hover' >
                        <PopoverTrigger>
                            <Stack bg={value==null?'transparent':'lightTema1.300'}  color={value==null?'lightTema1.300':'lightTema1.100'} w='full' h='44px' _dark={{ color:'lightTema1.100',bg:value==null?'transparent':'lightTema1.300'}} align={'flex-start'} justify='center' borderRadius={'lg'} cursor='pointer'  border={value==null?'2px dashed':'none'} >
                                <Text lineHeight={'20px'}  pt='16px' pl='10px' color={value==null?'lightTema1.300':'lightTema1.100'} fontWeight='bold' _dark={{ color:'lightTema1.100'}}  fontSize={'14px'} >{value==null?'Selecione':value.name}</Text>
                            </Stack> 
                        </PopoverTrigger>
                        <PopoverContent boxShadow={'xl'}  bg='bgMenuLight.100' _dark={{ bg:'bgMenuDark.100'}}  >
                            <PopoverArrow bg='transparent'   /> 
                            {ViewDados} 
                        </PopoverContent>
                    </Popover> 
                </Flex> 
            )
        break; 
    }
    
    
}