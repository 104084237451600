import { Box, HStack, PinInput, PinInputField, Stack, Text, useColorMode ,Icon, Flex, Skeleton, IconButton} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BgAnimatedAdmin} from "../../components/backgound/BgAnimatedLogin"; 
import { getPainelRoutes, loadPainelRoutes } from "./PagesAdmin";
import { conectarServer, sendFormSocket, user } from "../../components/session/SessionAdmin";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import  QRCode                  from    "react-qr-code";
import TemaAdmin from "./TemaAdmin";
import { getWebsite, website } from "../../theme/PageConfig";
import { TextK1 } from "../../components/text/TextKalyst";
import { config } from "../../theme/PageConfig";
import { ButtonKalyst } from "../../components/button/ButtonKalyst";
import { LinkK } from "../../components/button/LinkKalyst";
import { SiAuthy, SiGoogleauthenticator } from "react-icons/si";
import { FaAppStore, FaGooglePlay } from "react-icons/fa";
import BoxDialog from "../../components/modal/BoxDialog";
import { LuAlertTriangle } from "react-icons/lu";
export default function LayoutAdmin({page,location}){
    const [code,setCode]        =   useState(''); 
    const [base,setBase]        =   useState('');
    const [alert,setAlert]  =   useState({}); 
    
    const [userS,setUserS]      =   useState(null);
    const {colorMode,toggleColorMode} =useColorMode(); 
    if(colorMode =='light'){
        setTimeout(()=>{
            toggleColorMode();
        },100);
    } 
    useEffect(()=>{ 
        conectarServer();
        const interV  = setInterval(()=>{  
            setUserS(user);
        },500)
        return ()=>{
            clearInterval(interV);
        }
    },[]); 
    loadPainelRoutes(); 
    let url = '';
    page.forEach((p,i)=>{
        url+= "/"+p;
    });
    let LoadPage = null;
    getPainelRoutes().forEach((p,i)=>{
        if(url.startsWith(p.url.toUpperCase())){
            if(LoadPage == null || url== p.url.toUpperCase()){
                LoadPage = p;
            }
        }
    });

    if(LoadPage == null){
        return <Text>not found</Text>
    }else{ 
        if(LoadPage.login){
            //se  logado
            if(userS !== null){
                if(typeof userS.email == 'undefined' && typeof LoadPage.redirect != 'undefined' ){
                    LoadPage=getPainelRoutes()[0]; 
                }
            }
        }else{
            //se não logado
            if(typeof userS !=='undefined' && userS !== null){
                if(typeof userS.email != 'undefined' && typeof LoadPage.redirect != 'undefined' ){
                        return <Navigate to={LoadPage.redirect}/>
                }
            }
        }
        const CompPage = LoadPage.page;
        if(userS == null){
            return <Box bg={'bgLoginLight.100'} w='100vw' minH='100vh'>
                <Helmet>
                    <title>{LoadPage.title}</title>
                    <meta name='description' content='' /> 
                    <link rel="icon" href={"/web/"+getWebsite().url+"/favicon.ico"} />
                </Helmet>
                <BgAnimatedAdmin/> 
            </Box>;
        }else{ 
            if(typeof userS.s2fa!='undefined' &&!userS.s2fa){  
                if(base == ''){
                    sendFormSocket({ modulo:'user',cmd:'new2fa',form:{}, callback:(result)=>{ 
                        setBase(result.hash);
                    }});
                } 
                return <Box bg={'bgLoginLight.100'} w='100%' minH='100vh'>
                        <Helmet>
                            <title>{LoadPage.title}</title>
                            <meta name='description' content='' />
                            <link rel="icon" href={"/web/"+getWebsite().url+"/favicon.ico"} />
                        </Helmet>
                        <BgAnimatedAdmin/>
                        <Stack w='full' h='100vh' align='center' justify='center' bg={'lightTema1.100'} _dark={{bg:'darkTema1.100'}}> 
                                <Stack zIndex={'3'}  p={'20px'} bg={'lightTema1.200'} _dark={{bg:'darkTema1.200'}} gap='26px'> 
                                    <Stack w='320px'   p='20px' bg={'lightTema1.200'}> 
                                        <Skeleton isLoaded={base}>
                                        {/* 'otpauth://totp/'+config.empresa+'%20Admin:%20'+userS.email+'?secret=&issue=https%3A%2F%2Fpainel.fivepay.com.br%2Fweb%2Ffivepay.com.br%2Ffavicon.ico' */}
                                            <QRCode value={'otpauth://totp/'+config.empresa+'Admin:'+userS.email+'?secret='+base+'&issuer=2fa'}  bgColor="transparent" size={280}  />
                                        </Skeleton>
                                    </Stack>  
                                    <TextK1  textAlign='center' >Para sua segurança, ative a chave 2FA</TextK1>
                                    <HStack w={'full'}   align={'center'} justify='center' > 
                                        <PinInput manageFocus={true} type='alphanumeric' value={code} onChange={(v)=>{ 
                                            setCode(v.replace(/[^0-9 ]/gi, ''));
                                        }}   size={'lg'}>
                                            <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}} />
                                            <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                            <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                            <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                            <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                            <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                        </PinInput> 
                                    </HStack>   
                                    <Flex  w={'full'} justify='flex-end'>
                                        <LinkK onClick={()=>{ 
                                            setCode(''); 
                                        }} cursor='pointer'>Limpar Code</LinkK>
                                    </Flex>
                                     
                                    <Flex  w={'full'}  gap={'26px'}> 
                                         <Stack  w={'50%'} justify='space-between'  bg='lightTema1.200' _dark={{bg:'darkTema1.100'}} p='10px' gap={'10px'} align='center'>
                                            <Flex w={'full'} gap={'10px'}>
                                                <Icon as={SiGoogleauthenticator} h='30px' w='40px'/>
                                                <Stack gap={'0px'}>
                                                    <Text fontSize={'12px'} color='textAdmin.200'>Google</Text>
                                                    <Text fontSize={'12px'} color='textAdmin.100'> Authenticator</Text> 
                                                </Stack>
                                            </Flex>
                                            <Stack w={'full'} justify='flex-start' gap={'5px'}> 
                                                <Text fontSize={'10px'} color='textAdmin.300'> Baixar agora:</Text>  
                                                <Flex w={'full'} justify='flex-start' gap={'10px'}> 
                                                    <IconButton minW={'30px'} h='30px' icon={<FaGooglePlay/> } variant='ghost' onClick={()=>{
                                                        window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR', '_blank');
                                                    }}></IconButton>
                                                    <IconButton minW={'30px'} h='30px' icon={<FaAppStore/> } variant='ghost' onClick={()=>{
                                                        window.open('https://apps.apple.com/br/app/google-authenticator/id388497605', '_blank');
                                                        
                                                    }}/>
                                                </Flex>
                                            </Stack>
                                         </Stack>
                                         <Stack  w={'50%'} justify='space-between'  bg='lightTema1.200' _dark={{bg:'darkTema1.100'}} p='10px' gap={'10px'} align='center'>
                                            <Flex w={'full'} gap={'10px'}>
                                                <Icon as={SiAuthy} h='30px' w='40px'/>
                                                <Stack gap={'0px'}>
                                                    <Text fontSize={'12px'} color='textAdmin.200'>AUTHY</Text>
                                                    <Text fontSize={'12px'} color='textAdmin.100'> Twilio</Text> 
                                                </Stack>
                                            </Flex>
                                            <Stack w={'full'} justify='flex-start' gap={'5px'}> 
                                                <Text fontSize={'10px'} color='textAdmin.300'> Baixar agora:</Text>  
                                                <Flex w={'full'} justify='flex-start' gap={'10px'}> 
                                                    <IconButton minW={'30px'} h='30px' icon={<FaGooglePlay/> } variant='ghost' onClick={()=>{
                                                        window.open('https://play.google.com/store/apps/details?id=com.authy.authy', '_blank');
                                                    }}></IconButton>
                                                    <IconButton minW={'30px'} h='30px' icon={<FaAppStore/> } variant='ghost' onClick={()=>{
                                                        window.open('https://apps.apple.com/us/app/twilio-authy/id494168017', '_blank');
                                                        
                                                    }}/>
                                                </Flex>
                                            </Stack>
                                         </Stack>
                                         
                                    </Flex>
                                    <ButtonKalyst variant={'admin'} loaded={(code.length >= 6?true:false)} w='full' onClick={()=>{ 
                                        setAlert({load:true});
                                        sendFormSocket({ modulo:'user',cmd:'register2fa',form:{code:code}, callback:(result)=>{  
                                            if(result.erro){
                                                result.fechar =true;
                                                result.size = 'md';  
                                                console.log(result);
                                                result.icon = <Icon as={LuAlertTriangle} h={{base:'50px'}} w={{base:'50px'}} color='orange.300' />
                                                setAlert(result); 
                                            }else{
                                                setAlert({exit:true});

                                            }
                                        }});
                                    }}>Enviar Code</ButtonKalyst> 
                                    <Flex w={'full'} align={'center'} justify='center' gap={'10px'}>
                                        <TextK1>Mudar de conta</TextK1> 
                                        <LinkK href='login'>
                                            Fazer logoff
                                        </LinkK>
                                    </Flex> 
                            </Stack>
                            <BoxDialog param={alert}  />
                        </Stack> 
                    </Box>  
            }else{
                return <Box bg={'bgLoginLight.100'} w='100%' minH='100vh'>
                    <Helmet>
                        <title>{LoadPage.title}</title>
                        <meta name='description' content='' />
                        <link rel="icon" href={"/web/"+getWebsite().url+"/favicon.ico"} />
                    </Helmet>
                    <BgAnimatedAdmin/>
                    {LoadPage.tema!==false?<TemaAdmin page={page}><CompPage page={page}/></TemaAdmin> :<CompPage/> } 
                </Box>;
            }
            
        }
        
    }
    
}