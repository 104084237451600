import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input} from "@chakra-ui/react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import Typewriter from 'typewriter-effect';
import {   HK3, HK4 } from "../../../components/heading/HeadingKalyst";
import { SendForm } from "../../../components/form/SendForm";
import { object,string } from 'yup';
import { Helmet } from 'react-helmet-async';
import { TextK1 } from "../../../components/text/TextKalyst";
import { FaBeer } from "react-icons/fa"; 
import BoxDialog from "../../../components/modal/BoxDialog";
import { setSession } from "../../../components/session/SessionKalyst";
import { Navigate, Route } from "react-router-dom";
import { useState } from "react";
import { getWebsite } from "../../../theme/PageConfig";
export function PagePainelLogin({title}){
    const { colorMode } = useColorMode();
    const [redirect,setRedirect] = useState(null);  
    return <Flex zIndex={2} w='100vw'  > 
            <Flex zIndex={3} w='full' align={'center'} justify='center' maxW={'100vw'}  maxH={'100vh'}   margin = '0 auto' >
                <Flex w={{base:'full',lg:'full'}} maxW = 'calc(100%)'  h='100vh'   borderRadius={'5px'}>
                    <Stack display={{base:'none',lg:'flex'}} w={{base:'50%'}} p='0' position='relative' align={'center'} justify='center' >
                        <Stack  w={{base:'full'}} h={{base:'full'}} bg='lightTema1.300' overflow={'hidden'} p='0' position='absolute' top={'0px'} left={'0px'} align={'center'} justify='center' >
                            <Image zIndex={1} position={'absolute'}   w='full' src={'/assets/img/bglogin.webp'} alt='login' opacity={0.9}  borderRadius={'5px'}/>
                        </Stack>
                        
                        
                        <Stack zIndex={2} w='calc(100% - 40px)' align={'flex-end'} justify='center' maxW={'440px'}  gap={'15px'}>
                            <Image zIndex={1}   h='80px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} alt='login' opacity={0.7}  borderRadius={'5px'}/>
                            <HK3   color='textLight.300'>Aqui você vende</HK3>
                            <HK3 color='white' fontWeight={'bold'}> 
                                <Typewriter
                                    options={{
                                        strings: ['Tudo', 'Sempre', 'Mais'],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </HK3> 
                        </Stack>
                    </Stack>
                    <Stack w={{base:'calc(100% - 40px)',lg:'50%'}} bg='lightTema1.100' borderRadius={'0  5px 5px 0'}  _dark={{bg:'darkTema1.100'}} align={'center'} justify='center'  m={'0 auto'} > 
                        <Stack w='calc(100% - 40px)' align={'center'} justify='center' maxW={'440px'}  gap={'15px'}  py={{base:'30px'}} >
                            <SendForm  dados = {{modulo:'user',cmd:'login'}}
                                initial = {
                                    {
                                        login    :   '',
                                        senha    :   ''
                                    }
                                } 
                                schema={
                                    object({ 
                                        login:      string().required('E-mail é obrigatório'),
                                        senha:   string().required('Senha é obrigatória')
                                    })
                                } 
                                callback={(result)=>{ 
                                    setSession({token:result.hash.token,dados:result.hash.dados}); 
                                    setRedirect(<Navigate to={'/dashboard'} />);
                                }}
                                gap={'15px'}
                                > 
                                <Image zIndex={1} w='296px' m={'0 auto'}  h='80px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} display={{base:(colorMode=='light'?'none':'block'),lg:'none'}}   alt='login' opacity={1}  borderRadius={'5px'}/>
                                <Image zIndex={1} w='296px' m={'0 auto'}  h='80px'  src={'/web/'+getWebsite().url+'/logo_menu_dark.png'}  display={{base:(colorMode=='light'?'block':'none'),lg:'none'}}   alt='login' opacity={1}  borderRadius={'5px'}/>
                           
                                <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Acesse sua Conta</HK4>   
                           
                                <InputK  type='email' label="Email" name="login" placeholder="Digite seu email"    autoComplete={true}/>
                                <InputK type='password'  label="Senha" name="senha"  placeholder="Digite sua senha"    autoComplete={true}/> 
                                <Stack w={'full'} align={'flex-end'} justify='flex-end'>
                                    <LinkK href='/lost'>
                                        Esqueci a senha
                                    </LinkK>
                                </Stack>
                                <ButtonKalyst w='full'>
                                    Fazer Login
                                </ButtonKalyst>
                               
                            </SendForm>
                        </Stack> 
                    </Stack> 
                </Flex>
            </Flex>
            {redirect}
    </Flex>
}