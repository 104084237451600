import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Select,Skeleton,Text,others } from "@chakra-ui/react";
import { useState } from "react";

export default function SelectK({ w='100%',label,loaded=true,placeholder='',name='',onChange,error:errorForm={},help='',initial={},value=[],...others}){ 
    let errorM = '';
    let isError = false;   
    const [input, setInput] = useState((typeof initial[name]==='undefined')?'':initial[name]);  
    if(Object.keys(errorForm).length>0){  
        if(typeof errorForm[name] !== 'undefined'){
            errorM= errorForm[name]
            isError = true;
        }  
    }  
    return (
        <FormControl w={w} color={ input==''? '#ACACAC':'textLight.100'}    _dark={{ color:input==''? 'textDark.200':'textDark.100'}}  isInvalid={isError}  > 
            <FormLabel fontSize={{base:'10px',md:'12px',lg:'14px'}} noOfLines={'1'} title={label}  _dark={{ color:'textDark.100'}}>{label}</FormLabel>
            <Skeleton isLoaded={loaded}>
                <Flex border={'1px solid'} borderRadius='3px'   h={{base:'34px',md:'42px',lg:'50px'}}  borderColor={'gray.600 !important'}  >
                    <Select value={input}  onChange={(e)=>{
                        setInput(e.target.value) 
                        if(typeof onChange =='function'){
                            onChange(e);
                        }
                    }}  outline='none !important' border='none' boxShadow={'none !important'}  h={{base:'34px',md:'42px',lg:'50px'}}   fontSize={{base:'14px',md:'16px',lg:'18px'}} variant='outline'   placeholder={placeholder} _selected={{}} _focus={{outline:'none'}}   name={name}  {...others}>
                        {value.map((v,pos)=>{
                            var select = false;
                            if(v.value == input){
                                select = true;
                            }
                            return <option value={v.value}   key={name+''+pos}>{v.name}</option>
                        })} 
                    </Select>
                </Flex>
            </Skeleton>
            
            <Box   h={{base:'12px', md:'13px',lg:'14px'}} pt='4px' >
                {!isError ? (
                    <FormHelperText data-test='error-help' p={0} m='0'  h={{base:'12px', md:'13px',lg:'14px'}} fontSize={{base:'12px', md:'13px',lg:'14px'}}>
                        {help}
                    </FormHelperText>
                ) : (
                    <FormErrorMessage data-test='error-message'  p={0} m='0'  h={{base:'12px', md:'13px',lg:'14px'}} fontSize={{base:'12px', md:'13px',lg:'14px'}}><Text noOfLines={'1'} color={'red'} title={errorM}>{errorM}</Text></FormErrorMessage>
                )}  

            </Box>
        </FormControl>
    )
}