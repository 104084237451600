import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress, Tag, PinInput, PinInputField, HStack, useToast} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket } from "../../../components/session/SessionAdmin"; 
import { setSession } from "../../../components/session/SessionKalyst"; 
import { LuBox, LuLineChart, LuPen, LuTrash, LuUser2, LuUsers2 } from "react-icons/lu"; 
import ListResponsive from "../../../components/list/ListResponsive";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import FormClint from "./FormClient";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";
import ListBox from "../../../components/list/ListBox";
import { MdBlock, MdLogin, MdSecurity } from "react-icons/md";
import FormDocumentClient from "./FormDocumentClient";
import { TextK1 } from "../../../components/text/TextKalyst";
import FormAdquirenteCliente from "./FormAdquirenteCliente";
import FormRegistroCliente from "./FormRegistroCliente";


export function PageAdminClient(props){  
    const [open,setOpen]    =   useState(false); 
    const [dados,setDados]  =   useState(null); 
    const [edit,setEdit]    =   useState({}); 
    const [alert,setAlert]  =   useState({});  
    const [code,setCode]    =   useState({});
    const [size,setSize]    =   useState('full');
    const toast = useToast();
    
    const [viewBox,setViewBox]    =   useState(null)
    const hoje = new Date(); 
    let tempLoja = null; 
    function BuscarDados(reload){ 
        if(LojaSelect !==tempLoja){
            tempLoja = LojaSelect
            reload = true; 
        } 
        sendFormSocket({campo:'pages', modulo:'user',cmd:'list',form:{reload,loja:LojaSelect},callback:(result)=>{  
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [
            {name:'Telefone',   campo:'telefone'        ,w:2}, 
            {name:'Documento',  campo:'documento'       ,w:2}, 
            {name:'Data',       campo:'criacao'         ,w:1}   
        ];
       
        const lista = []
        if(dados.users.length>0){
            dados.users.forEach((cli)=>{
                if(typeof cli.saldos =='undefined'){
                    cli.saldos = {};
                }
                lista.push({
                    id          :   cli.id,
                    title       :   cli.nome, 
                    sub         :   <>
                        <Text fontSize={{base:'10px',md:'12px'}} color={'textAdmin.100'}>Total Vendas</Text>
                        <Text fontSize={{base:'10px',md:'12px'}} color={'textAdmin.300'}>R$ {(cli.saldos.vendas*0.01  ).toLocaleString('pt-BR',{minimumFractionDigits:2})}</Text>
                    </>,
                    nome        :   cli.nome,  
                    documento   :   cli.documento,  
                    telefone    :   cli.telefone,  
                    email       :   cli.email,     
                    nlojas      :   cli.nlojas, 
                    status      :   <Tag size={'sm'} minW={'max-content'} colorScheme={cli.status.c}>{cli.status.n}</Tag>,  
                    criacao     :   new Date(cli.criacao).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})
                })
            }) 
        }  
    
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Clientes</PageHK> 
                <ListBox titles={titles} dados={lista} cmd={[
                    { icon:<LuPen/>,name:'Plano', click:(d)=>{  
                        setEdit(d.id);  
                        setOpen(true);
                        setSize('full');
                        setViewBox(
                            <FormAdquirenteCliente  title={d.nome+' -  adquirentes'}  open={open} setOpen={(el)=>{ 
                              
                                setOpen(el)
                                setEdit(null); 
                                setViewBox(null)
                            }} edit={d.id} setEdit={setEdit}/>
                        );  
                    }}, 
                    { icon:<LuPen/>,name:'Dados', click:(d)=>{  
                        setEdit(d.id);  
                        setOpen(true);
                        setSize('full');
                        setViewBox(<FormDocumentClient  title={'Aprovação de Cliente'}  open={open} setOpen={(el)=>{ 
                            
                            setOpen(el)
                            setEdit({}); 
                        }} edit={d.id} setEdit={setEdit}/>);  
                    }}, 
                    { icon:<MdLogin/>,name:'Acessar', click:(d)=>{  
                        var sendCode = '';
                        setAlert({load:true});
                        console.log(window.location);
                        setTimeout(()=>{ 

                            setAlert({
                                fechar:false,
                                icon   : <Icon as={MdSecurity} h={{base:'50px'}} w={{base:'50px'}} color='green.300' />, 
                                titulo:'Acessar Conta',
                                size:'md',
                                mensagem:<Stack w={'full'} justify='center'>
                                            <Text color={'textAdmin.300'} fontWeight='bold'  textAlign='center'>{d.nome}</Text>
                                            <TextK1  textAlign='center'>Digite sua chave de segurança</TextK1>  
                                            <HStack w={'full'} pt='20px'  align={'center'} justify='center'> 
                                                <PinInput manageFocus={true} type='alphanumeric'focus onChange={(v)=>{
                                                    sendCode=v; 
                                                }}   size={'lg'}>
                                                    <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}} />
                                                    <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                                    <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                                    <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                                    <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                                    <PinInputField bg='lightTema1.200' color='textAdmin.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                                                </PinInput>
                                            </HStack> 
                                        </Stack>, 
                                
                                botao:[
                                    {
                                        nome:'SIM', 
                                        variant:'admin',
                                        close:true,
                                        cmd:()=>{   
                                            
                                            sendFormSocket({ modulo:'user',cmd:'login2fa',form:{code:sendCode,user:d.id},callback:(result)=>{ 
                                                if(result.erro){
                                                    toast({
                                                        title:result.titulo,
                                                        description: result.mensagem[0].mensagem,
                                                        status: 'error',
                                                        position:'top-right',
                                                        duration: 6000,
                                                        isClosable: true,
                                                    });
                                                }else{
                                                    toast({
                                                        title:'Acesso Liberado',
                                                        description: 'Foi aberto uma nova janela com o acesso ao cliente:'+d.nome,
                                                        status: 'success',
                                                        position:'top-right',
                                                        duration: 6000,
                                                        isClosable: true,
                                                    });  
                                                    setAlert({exit:true});  
                                                    setSession(result.hash);
                                                    setTimeout(()=>{ 
                                                        window.open((window.location.protocol+'//'+window.location.host+'/'), '_blank');
                                                    },500);
                                                }
                                                
                                                
                                                // setTimeout(()=>{
                                                //     setAlert({exit:true});
                                                //     BuscarDados(false); 
                                                // },500)
                                            }}); 
                                        }
                                    },
                                    {
                                        nome:'NÃO',
                                        variant:'ghost',
                                        cmd:()=>{   
                                            setCode('');
                                        }
                                    }
                                ]
                            });
                        }) 
                    }},
                    { icon:<MdBlock/>,name:'Bloquear', click:(d)=>{  
                        setAlert({load:true});
                        setTimeout(()=>{ 
                            setAlert({
                            fechar:true,
                            icon   : <Icon as={MdBlock} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                            titulo:'Bloquear Cliente',
                            size:'md',
                            mensagem:'Deseja realmente bloquear o cliente <b>'+d.nome+'<b>?', 
                            botao:[
                                {
                                    nome:'SIM', 
                                    cmd:()=>{
                                        setAlert({load:true}); 
                                        sendFormSocket({ modulo:'client',cmd:'blockUser',form:{codusu:d.id},callback:(result)=>{ 
                                            setTimeout(()=>{
                                                setAlert({exit:true});
                                                BuscarDados(false); 
                                            },500)
                                        }});
                                    }
                                },
                                {
                                    nome:'NÃO',
                                    variant:'ghost',
                                }
                            ]
                            });
                        }) 
                    }}, 
                    { icon:<LuTrash/>,name:'Deletar', click:(d)=>{  
                        setAlert({load:true});
                        setTimeout(()=>{ 
                            setAlert({
                            fechar:true, 
                            icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                            titulo:'Deletar Cliente',
                            size:'md',
                            mensagem:'Deseja realmente deletar o cliente <b>'+d.nome+'<b>?', 
                            botao:[
                                {
                                    nome:'SIM', 
                                    cmd:()=>{
                                        setAlert({load:true});
                                        sendFormSocket({ modulo:'client',cmd:'deleteUser',form:{codusu:d.id},callback:(result)=>{ 
                                            setTimeout(()=>{
                                                setAlert({exit:true});
                                                BuscarDados(false); 
                                            },500)
                                        }});
                                    }
                                },
                                {
                                    nome:'NÃO',
                                    variant:'ghost',
                                }
                            ]
                            });
                        }) 
                    }}
                ]} />  
                <ButtonKNew onClick={()=>{
                     setSize('2xl');
                      setViewBox(<FormRegistroCliente  title={'Cadastros de Cliente'} variant='admin'  open={open} setOpen={(el)=>{ 
                       
                        setOpen(el)
                        setEdit({}); 
                    }}  setEdit={setEdit}/>);  
                    setOpen(true);
                    setEdit(null);
                }}>Cadastrar Cliente</ButtonKNew>
                 <ModalPage  open={open}   isCentered={false} size={size} setOpen={setOpen}>
                    {viewBox}
                </ModalPage> 
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}