import { Flex, Spacer, Spinner, Stack, Text, Textarea, useToast } from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { CardPlanos } from "../../../components/card/CardPlanos";
import { SendForm } from "../../../components/form/SendFormAdmin";
import { HK5,SubHK } from "../../../components/heading/HeadingKalyst"; 
import SelectK from "../../../components/select/SelectKalyst";

 
export default function FormSubConta({dados,title='Selecione a adquirente',setOpen,modulo,cmd,BuscarDados}){ 
    const [reload,setReload] = useState(false); 
    const [temp,setTemp] = useState(dados.dataUP);
    const [dadosUsu,setDadosUsu] = useState(null)
    const toast = useToast();   
    if(temp!=dados.dataUP){
        setTemp(dados.dataUP)
        setReload(true); 
    }
    useEffect(()=>{
        var interV = setInterval(()=>{ 
            BuscarDados();
        },1000)
        return ()=>{
            clearInterval(interV);
        }
    },[])
    var pSub = dados[0];
    dados.subs.forEach((s)=>{
        if(s.padrao=='S'){
            pSub=s;
        }
    }) 
    //subadquirentes
    var subs = {};
    var subsPix = [];
    var subsCard = [];
    var subsBol = [];
    dados.subs.forEach((s)=>{
        subs[s.id] = s;
        if(s.pix == 'S'){
            subsPix.push({
                value   :   s.id,
                name    :   s.nome
            })
        }
        if(s.card == 'S'){
            subsCard.push({
                value   :   s.id,
                name    :   s.nome
            })
        }
        if(s.boleto == 'S'){
            subsBol.push({
                value   :   s.id,
                name    :   s.nome
            })
        }
    });
     
    //planos
    var plano   =   {};
    var planos  =   [];
    dados.plans.forEach((p)=>{
        plano[p.id] = p;
        planos.push({
            value:p.id,
            name: p.nome
        })
    }) 
    if(reload){
        setTimeout(()=>{
            setReload(false);
        },0);
        return   <></>;
    }else{ 
       
        if(dadosUsu == null){
            setDadosUsu( {  
                pix         :   dados.user.apiPix==null?'':dados.user.apiPix.toString(),
                card        :   dados.user.apiCar ==null?'':dados.user.apiCar.toString(),
                boleto      :   dados.user.apiBol==null?'':dados.user.apiBol.toString(),
                plano       :   dados.user.codpla ==null?'':dados.user.codpla.toString(),
            });
        }
        let tempS = dadosUsu; 
        return (
            <Stack>
                <HK5  color={'textLight.100'} _dark={{color:'textDark.100'}} pb='32px'>{title}</HK5>
                <SendForm  dados = {{modulo:'client',cmd:'saveAcquirer',form:{codusu:dados.user.id}}}  
                        initial={tempS}
                        schema={ 
                            object({  
                                plano:       string().required('Conta é obrigatório')   
                            })
                        } 
                        callback={(result)=>{ 
                            setOpen(false); 
                        }}
                        gap={'10px'}
                        >    
                        <Flex flexDirection={{base:'column',md:'row'}} gap={{base:'5px',md:'20px'}}>
                            <SelectK label={'Plano Taxa'} onChange={(v)=>{ 
                                tempS.plano = v.target.value; 
                                setDadosUsu(tempS);
                            }}  placeholder="Selecione" value={planos} name='plano' ></SelectK>  
                            {/* <SelectK label={'Pix'}     onChange={(v)=>{ 
                                tempS.pix = v.target.value; 
                                setDadosUsu(tempS);
                            }}      placeholder="Selecione" value={subsPix} name='pix' ></SelectK> 
                            <SelectK label={'Cartão'}   onChange={(v)=>{
                                tempS.card = v.target.value; 
                                setDadosUsu(tempS);
                            }}     placeholder="Selecione" value={subsCard} name='card' ></SelectK> 
                            <SelectK label={'Boleto'}     onChange={(v)=>{
                                tempS.boleto = v.target.value; 
                                console.log(tempS);
                                setDadosUsu(tempS);
                            }}   placeholder="Selecione" value={subsBol} name='boleto' ></SelectK>  */}
                        </Flex>
                        <CardPlanos form={dadosUsu}   subs={subs} plano={plano}/>
                        <Flex gap={'20px'} justify='flex-end'>
                            <ButtonKalyst variant={'admin'}   onClick={()=>{
                                // sendDados()
                                // BuscarDados();
                            }} >Salvar</ButtonKalyst>
                        </Flex>
                        
                </SendForm> 
            </Stack>
                
        ) 
    }
}