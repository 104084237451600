import { Flex, SimpleGrid, Stack,Box, Text, SkeletonText, Skeleton} from "@chakra-ui/react"; 

export function CardPlanos({form={},plano={},subs={}}){  
    const dPlan     =   typeof plano[form.plano]    == 'undefined'?null: plano[form.plano];
    console.log(subs);
    const dPix      =   typeof subs[form.pix]       == 'undefined'?null: subs[form.pix];
    const dCard     =   typeof subs[form.card]      == 'undefined'?null: subs[form.card];
    const dBoleto   =   typeof subs[form.boleto]    == 'undefined'?null: subs[form.boleto];
   
    return <Stack w={'full'}>
        <SimpleGrid bg='bgAdmin.200' columns={{lg:3}} spacingX='10px' p={'10px'} spacingY='10px'> 
            <CardPlanosInfo titulo={'Taxa PIX'}     
                fixo        =   {dPlan==null?null:dPlan.pixFixo} 
                porcentagem =   {dPlan==null?null:dPlan.pixPor} 
                reserva     =   {dPlan==null?null:dPlan.pixRes}

                fixoAD         =   {dPix==null?null:dPix.pixFixo} 
                porcentagemAD  =   {dPix==null?null:dPix.pixPor} 
                reservaAD      =   {dPix==null?null:dPix.pixRes}
            /> 
            <CardPlanosInfo titulo={'Taxa Cartão'}      
                fixo        =   {dPlan==null?null:dPlan.cardFixo} 
                porcentagem =   {dPlan==null?null:dPlan.p1} 
                reserva     =   {dPlan==null?null:dPlan.cardRes}

                fixoAD        =   {dCard==null?null:dCard.cardFixo} 
                porcentagemAD =   {dCard==null?null:dCard.p1} 
                reservaAD     =   {dCard==null?null:dCard.cardRes}
            /> 
            <CardPlanosInfo titulo={'Taxa Boleto'}     
                fixo        =   {dPlan==null?null:dPlan.boletoFixo} 
                porcentagem =   {dPlan==null?null:dPlan.boletoPor} 
                reserva     =   {dPlan==null?null:dPlan.boletoRes} 
                fixoAD        =   {dBoleto==null?null:dBoleto.boletoFixo} 
                porcentagemAD =   {dBoleto==null?null:dBoleto.boletoPor} 
                reservaAD     =   {dBoleto==null?null:dBoleto.boletoRes}
            /> 
        </SimpleGrid>
        <Text color='gray.200' fontSize='14px'>Parcelamento:</Text>
        <CardPlanosParcela card={dPlan}  cardAD={dCard}/>
        
    </Stack>
}
function CardPlanosParcela({card=null,cardAD=null}){
    
    var por = [];
    for(var m = 1; m<=12;m++){
        por.push(m)
    }
    return <Flex bg='bgAdmin.200'   p={'10px'}  gap='0px' w='full'>
        <SimpleGrid  columns={{base:3,md:6,xl:12}} spacingX='10px'  spacingY='10px' w='full'>
            {por.map((b,n)=>{
                return (
                    <Stack key={'poc'+n}  w='full' justify='space-between'  bg='bgAdmin.100' align='center' gap='0' p={{base:'5px',xl:'10px'}}>
                        
                        
                      
                        <Flex w='full'  justify='space-between'  bg='bgAdmin.100' align='center' gap={{base:'5px',xl:'10px'}} >
                            <Text color='textAdmin.300' fontSize={{base:'12px',xl:'14px'}} fontWeight='bold'>{n+1}x</Text>
                            {card==null?
                            <Skeleton>
                                <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>0.00%</Text>
                            </Skeleton>
                            :
                            <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>{card['p'+(b)]}%</Text>
                            } 
                        </Flex>
                        <Flex  w='full'  justify='space-between'  bg='bgAdmin.100' align='center' gap={{base:'5px',xl:'10px'}} >
                                <Text color='gray.500' fontSize={'10px'} fontWeight='bold'>AD {n+1}x</Text>
                                {cardAD==null?
                                    <Skeleton>
                                        <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>0.00%</Text>
                                    </Skeleton>
                                    :
                                    <Text  fontSize={'10px'}>{cardAD['p'+(b)]}%</Text> 
                                }
                                
                            </Flex>
                        {/* {cardAD!=null?
                            <Flex  w='full'  justify='space-between'  bg='bgAdmin.100' align='center' gap={{base:'5px',xl:'10px'}} >
                                <Text color='gray.500' fontSize={'10px'} fontWeight='bold'>AD {n+1}x</Text>
                                <Text  fontSize={'10px'}>0%</Text> 
                            </Flex>:
                            
                        }  */}
                    </Stack>
                )
            })} 
        </SimpleGrid>
    </Flex>
}
function CardPlanosInfo({titulo=null, fixo=null, porcentagem=null, reserva=null,fixoAD=null,porcentagemAD=null,reservaAD=null}){ 
    // console.log(fixoAD);
    return <Stack bg='bgAdmin.100'   p={'10px'}  gap='0px'> 
        <Text color='gray.200' fontSize='14px'>{titulo}</Text>
        <Flex w='full' justify='space-between' pt='10px' gap={'20px'} >
            <Flex w='full' justify='space-between' align='center' gap='5px'>
                <Text color='textAdmin.300' fontSize={{base:'12px',xl:'14px'}}>Fixo:</Text>
                {fixo==null?
                    <Skeleton>
                        <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>R$ 0,00</Text>
                    </Skeleton>:
                    <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>{'R$ '+fixo.toLocaleString('pt-BR',{minimumFractionDigits:2})}</Text>
                }
                
            </Flex>
            <Flex w='full' justify='space-between' align='center' gap='5px'> 
                <Text color='textAdmin.300' fontSize={{base:'12px',xl:'14px'}}>Variável:</Text>
                {porcentagem==null?
                    <Skeleton>
                        <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>R$ 0,00</Text>
                    </Skeleton>:
                    <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>{porcentagem.toLocaleString('pt-BR',{minimumFractionDigits:2})+'%'}</Text>
                }
                
            </Flex> 
            <Flex w='full' justify='space-between' align='center' gap='5px'> 
                <Text color='textAdmin.300' fontSize={{base:'12px',xl:'14px'}}>Reserva:</Text>
                {reserva==null?
                    <Skeleton>
                        <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>R$ 0,00</Text>
                    </Skeleton>:
                    <Text color='textAdmin.200' fontSize={{base:'12px',xl:'14px'}}>{reserva.toLocaleString('pt-BR',{minimumFractionDigits:2})+'%'}</Text>
                }
                
            </Flex>  
        </Flex>
        <Flex w='full' justify='space-between' pt='10px' gap={'20px'} > 
            <Flex w='full' justify='space-between' align='center' gap='5px'>
                <Text color='gray.500' fontSize='10px'>ADQ Fixo:</Text>
                {fixoAD== null? <Skeleton><Text fontSize='10px'>R$ 0,00</Text></Skeleton> :  <Text fontSize='10px'>{'R$ '+fixoAD.toLocaleString('pt-BR',{minimumFractionDigits:2})}</Text>}
            </Flex>
            <Flex w='full' justify='space-between' align='center' gap='5px'> 
                <Text color='gray.500' fontSize='10px'>ADQ Variável:</Text>
                { porcentagemAD== null? <Skeleton><Text fontSize='10px'>R$ 0,00</Text></Skeleton> :  <Text fontSize='10px'>{porcentagemAD.toLocaleString('pt-BR',{minimumFractionDigits:2})+'%'}</Text>}
             
            </Flex>
            <Flex w='full' justify='space-between' align='center' gap='5px'> 
                <Text color='gray.500' fontSize='10px'>ADQ Reserva:</Text>
                { reservaAD== null? <Skeleton><Text fontSize='10px'>R$ 0,00</Text></Skeleton> :  <Text fontSize='10px'>{ reservaAD.toLocaleString('pt-BR',{minimumFractionDigits:2})+'%'}</Text>}
    
            </Flex>
        </Flex>
    </Stack>
}