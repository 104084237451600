import axios from "axios";
import { useEffect, useState } from "react";  
import { srvTime } from "./session/SessionKalyst"; 
import { srvTime as srvTimeAD } from "./session/SessionAdmin"; 
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { CSVLink, CSVDownload } from "react-csv";
import { ButtonKalyst } from "./button/ButtonKalyst";
import { LuDownload } from "react-icons/lu";
import { Input } from "@chakra-ui/react";


const WSAPI = '153221790knNRpsmIhu276637296';
export function gerarPassword() {
    return Math.random().toString(36).slice(-10);
};
export function  ImportCSV(event,onChange){   
    try {
        var file    =   event.target.files[0]; 
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        onChange({error:false,dados:data}); 
        };

        fileReader.onerror = (error) => {
            onChange({error:true,message:error.message}); 
        };
    } catch (error) {
        onChange({error:true,message:error.message}); 
    }      
} 
export const ExportCSV = ({ csvData, fileName}) => { 
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx'; 
    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    } 
    return ( 
        <ButtonKalyst w={'full'}  h='30px' leftIcon={<LuDownload/> } onClick={(e) => exportToCSV(csvData, fileName)}  >Baixar</ButtonKalyst>  
    )
};
export function wsCEP(cnpj,callback){ 
    axios({
        method: 'get',
        url: 'https://ws.hubdodesenvolvedor.com.br/v2/cep3/?cep='+cnpj+'&token='+WSAPI,
        responseType: 'json'
    })
    .then(function (response) { 
        console.log(response);
        if(response.data.status){
            callback(response.data.result);
        } 
    })
}
export function viewPrice(num,decimal=2){
    return (num*0.01).toLocaleString('pt-BR',{minimumFractionDigits:decimal})
}
export const parseValor=( num,decimal=2)=>{
    var numN = {
        p:0,
        d:0,
        f:0, 
        fv:0
    } 
    if(num>=0){  
        var valor = parseFloat(num).toFixed((decimal+2)).toString(); 
        valor     =   valor.substring(0, valor.length - 2);  
        var pos   =   parseFloat(valor).toString().split('.'); 
        numN.p    = pos[0].replace(/(.)(?=(\d{3})+$)/g,'$1.'); 
        numN.d    = (typeof pos[1] =='undefined'?0:pos[1]);
        numN.f    =  numN.p+','+numN.d 
        numN.fv    =  numN.p+'.'+numN.d 
        return numN;
    }else{
      return numN;
    }  
}
export const TempoCronometro = (props)=>{
    const [time,setTime] = useState(props.time)
    useEffect(()=>{
        let interV = setInterval(()=>{    
            setTime(props.time - (props.admin?srvTimeAD:srvTime));
        },1000);
        return ()=>{
            clearInterval(interV);
        }
    },[time])  
    var msg = '';
    var d  = {
        d:0,
        h:0,
        m:0,
        s:0
    }
    var t = ((props.time - new Date().getTime())*0.001).toString().split('.')[0];
    if(props.view =='tempo'){
        if(t<0){
            t*=-1;
        }
        d.d = parseInt((t/86400).toString().split('.')[0]);
        var s=t%86400;
        d.h = parseInt((s/3600).toString().split('.')[0]);
        s=s%3600;
        d.m = parseInt((s/60).toString().split('.')[0]);
        d.s=s%60; 
        if(d.d>0){
            msg +=d.d>1?d.d+' dias - ':d.d+' dia - ';
            if(t>3600){
                msg +=d.h+'h ';
            }
        }else{
            if(t>3600){
                msg +=d.h+'h ';
            }
            if(t>60){
                msg +=d.m+'m ';
            }
            if(t>60){
                msg +=(d.s<=9?'0'+d.s:d.s)+'s ';
            }
        }
        
        
    }
    return msg
}
export const hexToRGB = (hex_value) => {
    const numericValue = parseInt(hex_value.replace('#',''), 16);
    const r = numericValue >> 16 & 0xFF;
    const g = numericValue >> 8 & 0xFF;
    const b = numericValue & 0xFF;
    return `${r}, ${g}, ${b}`
} 
export function validateSITE(site){

    var re = new RegExp("^(?:https?:\/)?(\/)", "gi");
    //  new RegExp("^(?:https?:\/\/)?(w{3}\.)?[\w_-]+((\.\w{2,}){1,2})(\/([\w\._-]+\/?)*(\?[\w_-]+=[^\?\/&]*(\&[\w_-]+=[^\?\/&]*)*)?)?$",) 
  
    if(re.test(site)){
        return true; 
    }else{
        return false;
    }
     
}
export function validateCPF(document) {
    document = document.replace(/[^\d]/g, ""); // Remove caracteres não numéricos
  
    if (document.length === 11) {
      // Se tem 11 dígitos, é CPF
      if (/^(.)\1+$/.test(document)) return false; // Verifica se não é uma sequência de números repetidos
  
      let sum = 0;
      for (let i = 0; i < 9; i++) sum += parseInt(document.charAt(i)) * (10 - i);
      let checkDigit1 = 11 - (sum % 11);
      if (checkDigit1 >= 10) checkDigit1 = 0;
  
      sum = 0;
      for (let i = 0; i < 10; i++) sum += parseInt(document.charAt(i)) * (11 - i);
      let checkDigit2 = 11 - (sum % 11);
      if (checkDigit2 >= 10) checkDigit2 = 0;
  
      return (
        checkDigit1 === parseInt(document.charAt(9)) &&
        checkDigit2 === parseInt(document.charAt(10))
      );
    } else {
      return false; 
    }
}
export function validateCNPJ(document) {
    document = document.replace(/[^\d]/g, ""); // Remove caracteres não numéricos
  
   if (document.length === 14) {
      // Se tem 14 dígitos, é CNPJ
      if (/^(.)\1+$/.test(document)) return false; // Verifica se não é uma sequência de números repetidos
  
      let size = document.length - 2;
      let numbers = document.substring(0, size);
      let digits = document.substring(size);
      let sum = 0;
      let pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += parseInt(numbers.charAt(size - i)) * pos--;
        if (pos < 2) pos = 9;
      }
      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != parseInt(digits.charAt(0))) return false;
  
      size = size + 1;
      numbers = document.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += parseInt(numbers.charAt(size - i)) * pos--;
        if (pos < 2) pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != parseInt(digits.charAt(1))) return false;
  
      return true;
    } else {
      return false; 
    }
}
export function nl2br (str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
} 
export const bancos = [
    {value:'1', name:'Banco do Brasil'},
    {value:'3', name:'Banco da Amazonia'},
    {value:'4', name:'Banco do Nordeste'},
    {value:'33', name:'Santander'},
    {value:'41', name:'Banrisul'},
    {value:'47', name:'Banco Banese'},
    {value:'77', name:'Inter'},
    {value:'103', name:'Mercado Pago'},
    {value:'104', name:'Caixa Econômica'},
    {value:'121', name:'Agibank'},
    {value:'128', name:'Crefisa'},
    {value:'129', name:'XP Investimentos'},
    {value:'173', name:'BRL Trust DTVM'},
    {value:'197', name:'Stone'},
    {value:'208', name:'Banco BTG Pactual'},
    {value:'212', name:'Banco Original'},
    {value:'237', name:'Bradesco/Next'},
    {value:'246', name:'Banco ABC Brasil'},
    {value:'260', name:'Nubank'},
    {value:'290', name:'Pagseguro'},
    {value:'318', name:'Banco BMG'},
    {value:'336', name:'Banco C6'},
    {value:'341', name:'Itaú'},
    {value:'348', name:'BANCO XP S.A.'},
    {value:'364-6', name:'Gerencianet Pagamentos do Brasil'},
    {value:'376', name:'JP Morgan'},
    {value:'383', name:'Juno'},
    {value:'389', name:'Mercantil do Brasil'},
    {value:'403', name:'Cora'},
    {value:'422', name:'Safra'},
    {value:'481', name:'PJBank'},
    {value:'536', name:'Neon/Votorantim'},
    {value:'613', name:'Banco Omni'},
    {value:'623', name:'Banco Pan'},
    {value:'637', name:'BCO SOFISA S.A.'},
    {value:'655', name:'Votorantim'},
    {value:'745', name:'Citibank'},
    {value:'748', name:'Sicredi'},
    {value:'756', name:'Sicoob'}
]  
export const estados = [ 
    {value:'AC', name:'Acre'},
    {value:'AL', name:'Alagoas'},
    {value:'AP', name:'Amapá'},
    {value:'AM', name:'Amazonas'},
    {value:'BA', name:'Bahia'},
    {value:'CE', name:'Ceará'},
    {value:'ES', name:'Espírito Santo'},
    {value:'GO', name:'Goiás'},
    {value:'MA', name:'Maranhão'},
    {value:'MG', name:'Mato Grosso'},
    {value:'MS', name:'Mato Grosso do Sul'},
    {value:'MG', name:'Minas Gerais'},
    {value:'PA', name:'Pará'},
    {value:'PB', name:'Paraíba'},
    {value:'PR', name:'Paraná'}, 
    {value:'PE', name:'Pernambuco'},
    {value:'PI', name:'Piauí'}, 
    {value:'RJ', name:'Rio de Janeiro'}, 
    {value:'RN', name:'Rio Grande do Norte'}, 
    {value:'RS', name:'Rio Grande do Sul'},  
    {value:'RO', name:'Rondônia'}, 
    {value:'RR', name:'Roraima'}, 
    {value:'SC', name:'Santa Catarina'},
    {value:'SP', name:'São Paulo'}, 
    {value:'SE', name:'Sergipe'},
    {value:'TO', name:'Tocantins'},
    {value:'DF', name:'Distrito Federal'}
]