import { Box, Flex, HStack, SimpleGrid, Icon,Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Text, Textarea, Stack } from "@chakra-ui/react";
import { HK4, HK5, PageHK, SubBHK, SubTHK } from "../../../components/heading/HeadingKalyst"; 
import { useEffect, useRef, useState } from "react"; 
import { sendFormSocket } from "../../../components/session/SessionAdmin"; 
import { getWebsite, website } from "../../../theme/PageConfig";
import FormDocumentFrente from "./InfoDocumentDados";
import ModalAprovarDados from "./ModalAprovarDados"; 
import { MdOutlineAssignment, MdOutlineAssignmentInd } from "react-icons/md";
import { TbCameraSelfie, TbFileTypePdf } from "react-icons/tb"; 
import { CiCircleList } from "react-icons/ci";
import ViewFileZoom from "../../../components/file/ViewFileZoom";
import FormSubConta from "./FormSubconta";
 

export default function FormDocumentClient( {open,setOpen,title,edit = null,setEdit}){  
    const [load,setLoadD] = useState(null); 
    const [reload,setReload] = useState(true);  
    const [fase,setFase] = useState(1);
    const [base,setBase] = useState(null);
    const [tipos,setTipos] = useState(null); 
    const [docs,setDocs] = useState(null);
    const [dadosG,setDadosG] = useState(null);   
    function BuscarDados(){
        sendFormSocket({campo:'pages', modulo:'user',cmd:'documentUser',form:{reload:reload,codusu:edit},callback:(result)=>{  
           
            setLoadD(1);
            setReload(false);
            setDadosG(result);  
            setBase(result.user); 
            setTipos(result.tipos);
            setDocs(result.docs);  
        }}); 
    } 
    useEffect(()=>{
        if(edit!==null){
            setLoadD(0); 
            setTimeout(()=>{
                BuscarDados();
            },50);
        }else{
            setLoadD(1);
        } 
    },[])   
    let color ={
        'P':'orange',
        'N':'green',
        'S':'green',
        'R':'red',
        'E':'gray'
    };
    let icons = [
        MdOutlineAssignmentInd ,
        MdOutlineAssignment ,
        TbCameraSelfie ,
        TbFileTypePdf  
    ] 
    useEffect(()=>{ 
    },[reload])
    if(!reload && load){   
        let Sub = false;
        if(base.sysBan == 'N' && base.sysRep == 'N'  && base.sysLoj == 'N' && base.sysInf == 'N' && base.sysEnd =='N' ){
            Sub = true;
        } 
        if(Sub){
            return  <FormSubConta setOpen={setOpen} dados = {dadosG} BuscarDados={BuscarDados}/>  
        }else{
            return (
                <>  
                    {title!=''?<HK5  color={'textLight.100'} _dark={{color:'textDark.100'}} pb='32px'>{title}</HK5>: null}
                    {load?
                    <Tabs isFitted w={'100%'}  defaultIndex={4}  minH='400px'  colorScheme="purple"  >
                        <TabList  w={'100%'}> 
                            {tipos.map((t,pos)=>{
                                var situa = 'E'; 
                                docs.forEach((e)=>{
                                    if( e.codtip ==t.id && ( e.situa =='P' || e.situa =='N' || e.situa =='R')){
                                        situa = e.situa;
                                    }
                                }); 
                                return <Tab key={'menu'+pos}  gap={'12px'}><Icon color={color[situa]} fontSize={'24px'}  as={ icons[pos]} /><Text  color={color[situa]} noOfLines={'1'} display={{base:'none',md:'initial'}} >{t.nome}</Text></Tab>
                            })} 
                            <Tab  key={'menudados' }  gap={'12px'}>
                                <Icon color={color[(base.sysDoc==null?'P':base.sysDoc)]} fontSize={'24px'}  as={ CiCircleList} />
                                <Text  color={color[(base.sysDoc==null?'P':base.sysDoc)]} noOfLines={'1'} display={{base:'none',md:'initial'}} >Formulário</Text>
                            </Tab>
                        </TabList>
                        <TabPanels> 
                            <TabPanel> 
                                {docs.map((e)=>{
                                    if(e.codtip ==1 && (e.situa =='P' || e.situa =='N')){ 
                                        return (
                                            <Flex key={'doc'+e.id} w='100%'>
                                                {e.situa=='P'?<ModalAprovarDados title="Deseja aprovar a Frente do Documento?" modulo="client" cmd='saveApprove' coddoc={e.id} BuscarDados={BuscarDados}/> :null}
                                                
                                                <ViewFileZoom file={{
                                                    type:e.type,
                                                    link:'https://subadquirente.'+getWebsite().url+'/'+e.link}
                                                    } 
                                                    maxW='initial'
                                                    w='90vw'
                                                    h='90vh'
                                                    m='0 auto'
                                                /> 
                                            </Flex>
                                            
                                        )     
                                                
                                    } 
                                })} 
                            </TabPanel>
                            <TabPanel>  
                                {docs.map((e)=>{
                                    if(e.codtip ==2 && (e.situa =='P' || e.situa =='N')){ 
                                        return (
                                            <Flex key={'doc'+e.id} w='100%'>
                                                {e.situa=='P'?<ModalAprovarDados title="Deseja aprovar a Verso do Documento?"  modulo="client" cmd='saveApprove' coddoc={e.id} BuscarDados={BuscarDados}/>:null}
                                                <ViewFileZoom  key={'doc'+e.id}  file={{
                                                    type:e.type,
                                                    link:'https://sub.'+getWebsite().url+'/'+e.link}
                                                    } 
                                                    maxW='initial'
                                                    w='90vw'
                                                    h='90vh'
                                                    m='0 auto'
                                                /> 
                                            </Flex> 
                                        )        
                                    } 
                                })} 
                            </TabPanel>
                            <TabPanel> 
                                {docs.map((e)=>{
                                    if(e.codtip ==3 && (e.situa =='P' || e.situa =='N')){ 
                                        return (
                                            <Flex key={'doc'+e.id} w='100%'>
                                                {e.situa=='P'?<ModalAprovarDados title="Deseja aprovar a Selfie do cliente?"  modulo="client" cmd='saveApprove' coddoc={e.id} BuscarDados={BuscarDados}/>:null}
                                                <ViewFileZoom  key={'doc'+e.id}  file={{
                                                    type:e.type,
                                                    link:'https://sub.'+getWebsite().url+'/'+e.link}
                                                    } 
                                                    maxW='initial'
                                                    w='90vw'
                                                    h='90vh'
                                                    m='0 auto'
                                                /> 
                                            </Flex>
                                        
                                        )     
                                                
                                    } 
                                })}  
                            </TabPanel>
                            <TabPanel w={'100%'}>   
                                {docs.map((e)=>{
                                    if(e.codtip ==4 && (e.situa =='P' || e.situa =='N')){ 
                                        return (
                                            <Flex key={'doc'+e.id} w='100%'>
                                                {e.situa=='P'?<ModalAprovarDados title="Deseja aprovar a Cartão CNPJ do cliente?"  modulo="client" cmd='saveApprove' coddoc={e.id} BuscarDados={BuscarDados} />:null}
                                                <ViewFileZoom  key={'doc'+e.id} file={{
                                                        type:e.type,
                                                        link:'https://sub.'+getWebsite().url+'/'+e.link
                                                    }} 
                                                    maxW='initial'
                                                    w='90vw'
                                                    h='90vh'
                                                    m='0 auto'
                                                />  
                                            </Flex> 
                                        )      
                                    } 
                                })} 
                            </TabPanel>   
                            <TabPanel>  
                                <Flex w={'100%'} flexDirection={{base:'column',md:'row'}} gap={'32px'}>
                                    <Stack w={'100%'}>
                                        <SubBHK   textAlign='left'>Empresa</SubBHK>   
                                        <FormDocumentFrente fase={1} setFase={setFase} dados={dadosG} BuscarDados={BuscarDados}/> 
                                            
                                        <SubBHK   textAlign='left'>Loja</SubBHK>   
                                        <FormDocumentFrente fase={2} setFase={setFase} dados={dadosG} BuscarDados={BuscarDados}/> 
                                            
                                        <SubBHK   textAlign='left'>Representante</SubBHK>   
                                        <FormDocumentFrente fase={3} setFase={setFase} dados={dadosG} BuscarDados={BuscarDados}/>
        
                                        <SubBHK   textAlign='left'>Endereço</SubBHK>   
                                        <FormDocumentFrente fase={4} setFase={setFase} dados={dadosG} BuscarDados={BuscarDados}/> 
        
                                        <SubBHK   textAlign='left'>Banco</SubBHK>   
                                        <FormDocumentFrente fase={5} setFase={setFase} dados={dadosG} BuscarDados={BuscarDados}/> 
                                                    
                                    </Stack>
                                    <Stack w={'100%'}  >  
                                        {docs.map((e)=>{
                                            if((e.codtip ==4)&& (e.situa =='P' || e.situa =='N')){ 
                                                return    <Flex key={'bos2'+e.id}   w='100%'    > 
                                                    <ViewFileZoom  key={'doc'+e.id}  file={{
                                                        type:e.type,
                                                        link:'https://sub.'+getWebsite().url+'/'+e.link}
                                                        } 
                                                        h='600px'
                                                        m='0 auto'
                                                    /> 
                                                </Flex>
                                            } 
                                        })} 
                                        {docs.map((e)=>{
                                            if((e.codtip ==1 || e.codtip ==2  )&& (e.situa =='P' || e.situa =='N')){ 
                                                return    <Flex key={'bos2'+e.id}   w='100%'    > 
                                                    <ViewFileZoom  key={'doc'+e.id}  file={{
                                                        type:e.type,
                                                        link:'https://sub.'+getWebsite().url+'/'+e.link}
                                                        } 
                                                        h='400px'
                                                        m='0 auto'
                                                    /> 
                                                </Flex>
                                            } 
                                        })}  
                                    </Stack> 
                                </Flex>  
                            </TabPanel>      
                        </TabPanels>
                    </Tabs>
                    :<Skeleton w={'100%'} h={'400px'}/>} 
                </>
            )
        }
       
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}


 