import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input} from "@chakra-ui/react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import Typewriter from 'typewriter-effect';
import {   HK3, HK4 } from "../../../components/heading/HeadingKalyst";
import { SendForm } from "../../../components/form/SendFormAdmin";
import { object,string } from 'yup';
import { Helmet } from 'react-helmet-async';
import { TextK1 } from "../../../components/text/TextKalyst";
import { FaBeer } from "react-icons/fa"; 
import BoxDialog from "../../../components/modal/BoxDialog";
import { setSession } from "../../../components/session/SessionAdmin";
import { Navigate, Route } from "react-router-dom";
import { useState } from "react";
import { getWebsite } from "../../../theme/PageConfig";
export function PageAdminLogin({title}){
    const { colorMode } = useColorMode();
    const [redirect,setRedirect] = useState(null); 
    
    return <Flex zIndex={2} w='100vw'  > 
            <Flex zIndex={3} w='full' align={'center'} justify='center' maxW={'1200px'}  minH={'100vh'}   margin = '0 auto' >
                <Flex w={{base:'600px',lg:'1200px'}} maxW = 'calc(100%)' minH={{base:'auto',md:'80%'}}    borderRadius={'5px'}>
                    <Stack w={{base:'calc(100% - 40px)',lg:'50%'}} backdropFilter='blur(60px)' bg={'rgba(255,255,255,0.05)'} borderRadius={'16px'}   align={'center'} justify='center'  m={'0 auto'} > 
                        <Stack w='calc(100% - 40px)' align={'center'} justify='center' maxW={'440px'}  gap={'15px'}  py={{base:'30px'}} >
                            <SendForm  dados = {{modulo:'user',cmd:'login'}}
                                initial = {
                                    {
                                        login    :   '',
                                        senha    :   ''
                                    }
                                } 
                                schema={
                                    object({ 
                                        login:      string().required('E-mail é obrigatório'),
                                        senha:   string().required('Senha é obrigatória')
                                    })
                                } 
                                callback={(result)=>{ 
                                    setSession({token:result.hash.token,dados:result.hash.dados}); 
                                    setRedirect(<Navigate to={'/admin/dashboard'} />);
                                }}
                                gap={'15px'}
                                > 
                                <Image zIndex={1} w='296px' m={'0 auto'}  h='80px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'}    alt='login' opacity={1}  borderRadius={'5px'}/>
                                 
                                <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Painel Admin</HK4>   
                           
                                <InputK  type='email' label="Email" name="login" placeholder="Digite seu email"    autoComplete={true}/>
                                <InputK type='password'  label="Senha" name="senha"  placeholder="Digite sua senha" variantBT="admin"    autoComplete={true}/> 
                               
                                <ButtonKalyst w='full' variant={'admin'} >
                                    Fazer Login
                                </ButtonKalyst> 
                            </SendForm>
                        </Stack> 
                    </Stack> 
                </Flex>
            </Flex>
            {redirect}
    </Flex>
}