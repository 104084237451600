import { Box, Flex, Icon, SimpleGrid, Stack, Text } from "@chakra-ui/react"; 
import { TextK1 } from "../../../components/text/TextKalyst";
import FormDocument from "./FormDocument";
import { LuCheck, LuCheckCheck, LuClock } from "react-icons/lu";
import { TriangleAlert } from "lucide-react";
export default function FaseDocument({fase,setFase,dados,BuscarDados}){   
    const fases = [
        {nome:'Arquivos'},
        {nome:'Informações'},
        {nome:'Gateway'},
        {nome:'Representante'},
        {nome:'Endereço'},
        {nome:'Banco'} 
    ]  
    let status={f0:'E',f1:'E',f2:'E',f3:'E',f4:'E',f5:'E',f6:'E'};
    status.f0 = 'E'; 
    if(dados.status['R']>0){
        status.f0='R';
    }else if(dados.status['E']>0){
        status.f0='E';
    }else if(dados.status['P']>0){
        status.f0='P'
    }else if(dados.status['N']==4){
        status.f0='N'; 
    }   
    if(dados.user.sysInf== 'P'|| dados.user.sysInf== 'N' || dados.user.sysInf== 'R'){
        status.f1 = dados.user.sysInf;
    }
    console.log(dados.user);
    if(dados.user.sysLoj== 'P'|| dados.user.sysLoj== 'N' || dados.user.sysLoj== 'R'){
        status.f2 = dados.user.sysLoj;
    }
    if(dados.user.sysRep== 'P'|| dados.user.sysRep== 'N' || dados.user.sysRep== 'R'){
        status.f3 = dados.user.sysRep;
    } 
    if(dados.user.sysEnd== 'P'|| dados.user.sysEnd== 'N' || dados.user.sysEnd== 'R'){
        status.f4 = dados.user.sysEnd;
    }  
    if(dados.user.sysBan== 'P'|| dados.user.sysBan== 'N' || dados.user.sysBan== 'R'){
        status.f5 = dados.user.sysBan;
    }    
    const info= {
        'E':{
            iconBar: null,
            color  : ''
        },
        'P':{
            iconBar: LuClock,
            color  : 'orange'
        },
        'R':{
            iconBar: TriangleAlert,
            color  : 'red'
        },
        'N':{
            iconBar: LuCheckCheck,
            color  : 'green.500'
        }
    }
    const viewMenu = fases.map((f,i)=>{
        return  ( 
            <Stack key={'passo'+i} position={'relative'} w={{base:fase==i?'100%':'40px',sm:'100%'}}  h={{base:'32px',lg:(fases.length-1 == i?'32px':i==fase?'90px':'90px')}} >
                <Flex  w='100%' h={{base:'30px',lg:'40px'}} bg={{base:fase==i?'rgba(255,255,255,0.5)':'transparent',lg:'transparent'}} _hover={{bg:'rgba(255,255,255,0.4)'}} borderRadius={'18px'}   onClick={()=>{
                    setFase(i);
                    window.location.hash = i;
                }}>
                    <Flex position={'absolute'} top={'4px'} left={'4px'} borderRadius={'100%'} align={'center'} justify={'center'} border='3px solid' bg={status['f'+i]=='E'?((i<=(fase))?'transparent':'transparent'):i==fase?'textLight.300':info[status['f'+i]].color}  borderColor={status['f'+i]=='E'?((i<=(fase))?'textLight.300':'textLight.200'):i==fase?'textLight.300':info[status['f'+i]].color} w={{base:'22px',lg:'32px'}} h={{base:'22px',sm:'22px',lg:'32px'}}  >
                        {info[status['f'+i]].iconBar== null?null:<Icon as={info[status['f'+i]].iconBar} fontSize={{base:'10px',sm:'14px',lg:'18px'}} />}
                    </Flex>
                    <Stack display={{base:'none',lg:'flex'}} position={'absolute'} borderLeft='3px dashed' color={(i<=(fase-1))?'textLight.300':'textLight.200'} top='37px' left={'19px'} h='calc(100% - 36px)'></Stack>
                    <Flex   pl={{base:'36px',lg:'46px'}} pt={{base:'3px',lg:'5px'}}  color={'textLight.200'} align={'flex-start'} justify='flex-start' gap={'10px'} >
                        <TextK1   lineHeight={{base:'22px',lg:'30px'}} noOfLines='auto'   _dark={{ color:'textDark.100'}}  pr={fase==i?'16px':'0px'} > 
                            <Text as='span'  lineHeight={{base:'22px',lg:'30px'}}   fontSize={{base:'10px' ,sm:'12px',lg:'25px'}} mr='5px' mt={{base:'0',lg:'3px'}} fontWeight='bold'  _dark={{ color:'textDark.100'}} display={{base:i==fase?'initial':'none',md:'initial'}} >0{i+1}</Text>
                            <Text as='span'  lineHeight={{base:'22px',lg:'30px'}}   fontSize={{base:'10px',sm:'12px',lg:'16px'}} mt={{base:'0',lg:'3px'}}  fontWeight='bold'  _dark={{ color:'textDark.100'}} display={{base:i==fase?'initial':'none',lg:'initial'}} >{f.nome}</Text>
                        </TextK1> 
                    </Flex>
                  
                </Flex> 
            </Stack> 
        )
    }) 
    return (
        <Flex flexDirection={{base:'column',lg:'row'}} w='full' gap={'16px'}>
            
            {/* <SimpleGrid   columns={{base:2,sm:3}} spacing={1} w='full' display={{base:'grid',lg:'none'}}>
                {viewMenu}
            </SimpleGrid> */}
            <Stack flexDirection={{base:'row',lg:'column'}}  w={{base:'full',lg:'200px'}}  gap={'0px'} >
                {viewMenu}
            </Stack>
            <Stack w='full'   minH='400px'>
                <FormDocument fase={fase} setFase={setFase} dados={dados} BuscarDados={BuscarDados}/>
            </Stack> 
        </Flex> 
    )
}